import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import PriceBlock from "../components/PriceBlock";
import Seo from "../components/Seo";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import {Fade} from "react-awesome-reveal";
import BG from "../assets/svgs/background.svg";

const Homepage = ({data}) => {

    const {wpPage: page, allWpPost} = data

    function renderBlogBlock() {
        return allWpPost.edges.map((node) => {
            if (node) {

                let post = node.node;
                let url = '/' + post.slug;

                return (
                    <a key={url} href={url}>
                        <div className="bg-white shadow-lg price-block flex flex-1 flex-col text-white cursor-pointer">
                            <GatsbyImage alt={post.title}
                                         placeholder="blurred"
                                         imgClassName="w-full"
                                         className="w-full"
                                         imgStyle={{objectFit: "cover"}}
                                         loading="lazy"
                                         image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}>
                            </GatsbyImage>
                        </div>
                        <div className="mt-3 text-center">
                            <span class="block tracking-widest text-xl font-medium uppercase">
                               blog
                            </span>
                            <span class="block text-lg text-gray-600 tracking-tight">{post.title}</span>
                        </div>
                    </a>
                )
            }
        })
    }

    function renderCarousel() {
        if (!page.acf_home) {
            return null
        }

        return (
            <Header carousel={page.acf_home.carousel !== undefined ? page.acf_home.carousel : null}/>
        )
    }

    function renderIntro() {
        if (!page.acf_home) {
            return null;
        }

        return (
            <Fade direction="up" damping={0} triggerOnce>
                <div className="grid grid-cols-1 text-center">
                    <h1 className="font-bold text-4xl mb-5">{page.acf_home.blok1Titel}</h1>
                    <div className="font-light content text-lg text-left"
                         dangerouslySetInnerHTML={{__html: page.acf_home.blok1Tekst}}/>
                </div>
            </Fade>
        )
    }

    return (
        <Layout>
            <Seo seo={page.seo}/>
            {renderCarousel()}
            <div className="my-20">
                <div className="container mx-auto lg:w-1/2 w-5/6">
                    {renderIntro(page)}
                </div>
            </div>
            <div className="pb-36">
                <div className="container mx-auto lg:w-2/4 w-5/6">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-16">
                        <Fade direction={"left"} triggerOnce>
                            <div>
                                {renderBlogBlock()}
                            </div>
                        </Fade>
                        <Fade>
                            <div>
                                <a href="/contact">
                                    <div
                                        className="bg-white shadow-lg price-block flex flex-1 flex-col text-white cursor-pointer">
                                        <StaticImage alt="Lets connect!" src="../assets/images/isabelle-contact.webp"
                                                     height={300} objectPosition="0% 0%"/>
                                    </div>
                                    <div className="mt-3 text-center">
                            <span className="block tracking-widest text-xl font-medium uppercase">
                               Lets connect
                            </span>
                                        <span className="block text-lg text-gray-600 tracking-tight">
                                        Neem contact op met mij
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </Fade>
                        {/*<Fade direction={"right"} triggerOnce>*/}
                        {/*    <div>*/}
                        {/*        <div*/}
                        {/*            className="bg-white shadow-lg price-block flex flex-1 flex-col text-white cursor-pointer">*/}
                        {/*            <BG height={300}  />*/}
                        {/*        </div>*/}
                        {/*        <div className="mt-3 text-center">*/}
                        {/*    <span className="block tracking-widest text-xl font-medium uppercase">*/}
                        {/*        Succesverhalen*/}
                        {/*    </span>*/}
                        {/*            <span className="block text-lg text-gray-600 tracking-tight">*/}
                        {/*                Neem contact op met mij*/}
                        {/*            </span>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Fade>*/}
                    </div>
                </div>
            </div>
            <PriceBlock/>
            {/*<Instagram />*/}
        </Layout>
    )
}

export default Homepage

export const homepageQuery = graphql`
  query HomePageById($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
    
     seo {  
          canonical
          title
          metaDesc
          focuskw
                
      }
      acf_home {
          blok1Titel
          blok1Tekst
           acfBlock {
        blockTitle
        blockUrl
        blockImage {
          mediaItemUrl
           localFile {
              publicURL
               childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
              }
            }
        }
      }
        carousel {
          secondTitle
          shortText
          firstTitle
          fieldGroupName
          image {
            id
            mediaItemUrl
            localFile {
              publicURL
                 childImageSharp {
                     gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
   allWpPost(sort: {fields: date, order: DESC}, limit: 1, filter: {categories: {nodes: {elemMatch: {name: {eq: "Blog"}}}}}) {
    edges {
      node {
        id
        title
        slug
        content
        date(locale: "nl", formatString: "DD MMMM, YYYY")
         featuredImage {
      node {
        id
        localFile {
              publicURL
               childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, height: 300)
              }
            }
      }
    }
      }
    }
  }  
  }
 `