import React from "react";
import { Link} from "gatsby";
import {GatsbyImage } from "gatsby-plugin-image"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Header = ({ carousel }) => {
  return (
          Object.values(carousel).map((slide, i) => {
              return (
                  <section className="text-black relative">
                      <GatsbyImage
                              placeholder="blurred"
                              imgStyle={{ objectFit: "cover" }}
                              loading="eager"
                              className="main-bg"
                              alt=""
                              image={slide.image.localFile.childImageSharp.gatsbyImageData}>
                      </GatsbyImage>
                      <div className="absolute left-0 right-0 top-0 bottom-0 bg-primary bg-opacity-20"></div>
                      <div className="bg-heading bg-primary relative lg:absolute lg:top-2/3 lg:left-1/2 -translate-x-1/2 -translate-y-1/2 transform mx-auto flex px-5 py-5 bg-opacity-80 items-center justify-center flex-col">
                             <Link to="/contact" className="group">
                              <div className="text-center w-full">
                                  <h1 className="font-medium title-font sm:text-4xl text-3xl mb-4">
                                     Vet verliezen zonder dieet?</h1>
                                  <p className="mb-8 leading-relaxed text-xl">Een persoonlijk afgestemd voedingsadvies & begeleiding. <br /> Gun jezelf een fit en gezond lijf.</p>
                                  <div className="flex justify-center">
                                      <button
                                          className="group-hover:border-black border-2 border-black text-center font-bold bg-black group-hover:bg-primary text-white group-hover:text-black rounded-md px-10 py-2 transition-colors w-2/3">
                                          Gratis kennismaken
                                      </button>
                                  </div>
                              </div>
                             </Link>
                      </div>
                  </section>
              );
          })
  )
}

export default Header;
